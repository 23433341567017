<template>
    <div class="px-0" :class="`${loading && 'pointer-events-none'}`" style="height: 100%">
        <h4 class="mt-4">Estrutura organizacional selecionada</h4>
        <DataTable
            dataKey="id"
            :value="hierarquias"
            :row-hover="true"
            responsiveLayout="scroll"
            breakpoint="640px"
            :rows="10"
            style="cursor: pointer"
            class="mb-3"
        >
            <template #empty>Nenhum registro encontrado.</template>
            <template #loading>Carregando registros. Aguarde...</template>
            <Column field="setor" header="Setor" style="width: 40%">
                <template #body="props">
                    <label class="status-display">
                        {{ props.data?.setor?.name }}
                    </label>
                </template>
            </Column>
            <Column field="cargo" header="Cargo" style="width: 40%">
                <template #body="props">
                    <label>
                        {{ props.data?.cargo?.name }}
                    </label>
                </template>
            </Column>
            <Column field="" bodyClass="text-right" header="" style="width: 10%">
                <template #body="props">
                    <Button class="p-button-text p-button-secondary" style="width: 100%" @click="handleRemoveHierarquia(props.index)"
                        ><span class="pi pi-trash mr-2" />Excluir</Button
                    >
                </template>
            </Column>
        </DataTable>
        <Button text label="Adicionar estrutura organizacional" icon="pi pi-plus" class="p-button-text" @click="openModal" />
        <div class="flex justify-content-end" style="margin-top: auto; padding-bottom: 3rem">
            <Button label="Voltar" @click="$router.push('/plano-acao/listar')" icon="pi pi-home" iconPos="left" type="button" class="mr-2" />
            <Button
                label="Salvar"
                @click="save"
                icon="pi pi-save"
                iconPos="left"
                :disabled="this.planoAcao.status !== 'PENDENTE'"
                :loading="loading"
            />
        </div>
    </div>
    <div class="col-12 pb-0 gap-2">
        <Dialog v-model:visible="showModal" modal :style="{ width: '462px' }">
            <template #header>
                <h4 class="mb-1">Selecionar estrutura organizacional</h4>
            </template>
            <div class="flex align-items-center pt-2 pb-2 my-4 gap-3">
                <InputSwitch v-model="todosOsSetores" class="mr-2" />
                <p class="m-0">Todas as hierarquias da unidade</p>
            </div>
            <label for="setor" class="input-label">Setores</label>
            <MultiSelect
                id="setor"
                v-model="setoresSelecionados"
                placeholder="Selecione"
                style="width: 100%"
                optionLabel="name"
                :options="filtrarSetores()"
                :disabled="todosOsSetores"
                filter
                class="my-3"
            />
            <label for="cargo" class="input-label mt-2">Cargos</label>
            <MultiSelect
                id="cargo"
                v-model="cargosSelecionados"
                placeholder="Selecione"
                style="width: 100%"
                optionLabel="name"
                :options="filtrarCargos()"
                :disabled="todosOsSetores || !setoresSelecionados.length"
                filter
                class="mt-2"
            />
            <template #footer>
                <Button label="Cancelar" @click="showModal = false" class="p-button-outlined mr-4 b-rounded" />
                <Button
                    :disabled="!todosOsSetores && (!this.cargosSelecionados.length || !this.cargosSelecionados.length)"
                    label="Confirmar"
                    @click="handleOnConfirmHierarquia()"
                    class="b-rounded"
                />
            </template>
        </Dialog>
    </div>
</template>

<script>
import PlanoAcaoHierarquiaService from '../../../services/planoAcaoHierarquiaService';
import BaseService from '../../../services/BaseService';

export default {
    data() {
        return {
            hierarquias: [],
            setores: [],
            cargos: [],
            customerHierarchies: [],
            selectedHierarquia: {},
            planoAcao: {},
            loading: false,
            submitted: false,
            showModal: false,
            todosOsSetores: false,
            setoresSelecionados: [],
            cargosSelecionados: {},
            actionItems: [
                {
                    label: () => (this.selectedEnvolvido.data.edit ? 'Salvar' : 'Alterar'),
                    icon: 'pi pi-pencil',
                    command: () => this.handleSetEditMode()
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.handleRemoveEnvolvido()
                }
            ]
        };
    },
    async mounted() {
        this.$service = new PlanoAcaoHierarquiaService();
        const { data: planoAcao } = await new BaseService('/plano-acao').findById(this.$route.params.id);
        this.planoAcao = planoAcao;

        if (this.$route.params.id) {
            await this.getHierarquias();
        }
    },
    methods: {
        async save() {
            this.submitted = true;
            this.loading = true;

            const { data } = await this.$service.get(`combo-plano-acao/${this.$route.params.id}`);

            const originalIds = data.map((element) => element.id);
            const newIds = this.hierarquias.map((element) => element.id);

            originalIds.forEach(async (id) => {
                if (id.length > 7) {
                    if (!newIds.includes(id)) {
                        await this.$service.delete(id).then(() => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Hierarquia removida.',
                                life: 3000
                            });
                        });
                    }
                }
            });

            this.hierarquias.forEach(async (hierarquia, index) => {
                let doubleCheck = false;

                data.forEach((element) => {
                    if (element.setorId === hierarquia.setor.id && element.cargoId === hierarquia.cargo.id) {
                        doubleCheck = true;
                    }
                });

                if (doubleCheck && hierarquia.id.length <= 7) {
                    this.loading = false;
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Hierarquia já cadastrada.',
                        life: 3000
                    });
                }

                try {
                    if (hierarquia.id.length > 7) {
                        const hierarquiaSelecionado = data.find((element) => element.id === hierarquia.id);

                        if (
                            hierarquia.setorId === hierarquiaSelecionado.setorId &&
                            hierarquia.cargoId === hierarquiaSelecionado.cargoId &&
                            hierarquia.customerHierarchieId === hierarquiaSelecionado.customerHierarchieId
                        ) {
                            return;
                        }

                        const dataToSave = {
                            setorId: hierarquia.setorId,
                            cargoId: hierarquia.cargoId,
                            customerHierarchieId: hierarquia.customerHierarchieId
                        };

                        await this.$service.patch(hierarquia.id, dataToSave).then((res) => {
                            this.hierarquias[index] = res.data;
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Hierarquia editada.',
                                life: 3000
                            });
                        });
                    } else {
                        if (!hierarquia.setor?.id || !hierarquia.cargo?.id) {
                            return this.$toast.add({
                                severity: 'error',
                                summary: 'Informe cargo/setor para cadastrar hierarquia.',
                                life: 3000
                            });
                        }

                        const selectedCustomerHierarchie = this.customerHierarchies.find((element) => {
                            return element.customerDepartmentId === hierarquia.setor.id && element.customerPositionId === hierarquia.cargo.id;
                        });

                        const dataToSave = {
                            planoAcaoId: this.$route.params.id,
                            setorId: hierarquia.setor.id,
                            cargoId: hierarquia.cargo.id,
                            customerHierarchieId: selectedCustomerHierarchie?.id
                        };

                        await this.$service.post(dataToSave).then((res) => {
                            this.hierarquias[index] = res.data;
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Hierarquia cadastrada.',
                                life: 3000
                            });
                        });
                    }
                    this.loading = false;
                } catch (error) {
                    console.log(error)
                    this.$toast.add({ severity: 'error', summary: error, life: 3000 });
                }
            });
            this.loading = false;
        },
        async destroy(id) {
            try {
                await this.$service.delete(id).then(() => {
                    this.$toast.add({ severity: 'success', summary: 'Estrutura organizacional removida com sucesso.', life: 3000 });
                    this.getHierarquias();
                });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro na criação da estrutura organizacional', life: 3000 });
            }
        },
        async getHierarquias() {
            const { data } = await this.$service.get(`combo-plano-acao/${this.$route.params.id}`);
            this.hierarquias = data;
        },
        async getSetores() {
            const { data: planoAcao } = await new BaseService('/plano-acao').findById(this.$route.params.id);
            const { data } = await new BaseService(`/customer/departments/combo-by-customer/${planoAcao.customerId}`).findAll({});
            this.setores = data;
        },
        async getCargos() {
            const { data: planoAcao } = await new BaseService('/plano-acao').findById(this.$route.params.id);

            const { data } = await new BaseService(`/customer/positions/combo-by-customer/${planoAcao.customerId}`).findAll({});
            this.cargos = data;
        },
        async getCustomerHierarchies() {
            const { data: planoAcao } = await new BaseService('/plano-acao').findById(this.$route.params.id);
            const { data } = await new BaseService(`/customer/hierarchies/by-branch/${planoAcao.unidadeId}`).findAll({});
            this.planoAcao = planoAcao;
            this.customerHierarchies = data;
        },
        addHierarquia(setor) {
            this.cargosSelecionados.forEach((cargo) => {
                this.hierarquias.push({ id: `${Math.floor(Math.random() * 100001)}`, setor: setor, cargo });
            });
        },
        handleOnConfirmHierarquia() {
            if (this.todosOsSetores) {
                this.setoresSelecionados = this.filtrarSetores().filter(
                    (item, index, array) => index === array.findIndex((prevItem) => prevItem.id === item.id)
                );
                this.cargosSelecionados = this.filtrarCargos().filter(
                    (item, index, array) => index === array.findIndex((prevItem) => prevItem.id === item.id)
                );
                this.filtrarSetores().forEach((setor) => {
                    this.addHierarquia(setor);
                });
            } else {
                this.setoresSelecionados.forEach((element) => {
                    this.addHierarquia(element);
                });
            }
            this.todosOsSetores = false;
            this.showModal = false;
            this.cargosSelecionados = [];
            this.setoresSelecionados = [];
        },
        handleRemoveHierarquia(index) {
            this.hierarquias.splice(index, 1);
        },
        filtrarSetores() {
            const setores = this.customerHierarchies
                .map((hierarquia) => hierarquia.customerDepartment)
                .filter((item, index, array) => index === array.findIndex((prevItem) => prevItem.id === item.id));
            return setores;
        },
        filtrarCargos() {
            const cargos = this.customerHierarchies.map((hierarquia) => {
                return { ...hierarquia.customerPosition, departmentId: hierarquia.customerDepartmentId };
            });

            return cargos
                .filter((cargo) => this.setoresSelecionados.map((elem) => elem.id).includes(cargo.departmentId))
                .filter((item, index, array) => index === array.findIndex((prevItem) => prevItem.id === item.id));
        },
        async openModal() {
            await this.getSetores();
            await this.getCustomerHierarchies();

            this.todosOsSetores = false;
            this.cargosSelecionados = [];
            this.setor = {};
            this.showModal = true;
        }
    },
    watch: {
        setores: function () {
            this.getCargos();
        },
        todosOsCargos() {
            this.filtrarCargos();
            this.filtrarSetores();
        },
        todosOsSetores() {
            if (this.todosOsSetores) {
                this.setor = {};
            }
            this.filtrarCargos();
            this.filtrarSetores();
        }
    }
};
</script>

<style scoped></style>
