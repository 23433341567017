<template>
    <div class="grid" :class="`${loading && 'pointer-events-none'}`">
        <div class="col-5 pl-0">
            <label for="customer" class="input-label">Cliente *</label>
            <Dropdown id="customer" name="customer" v-model="form.customerId" style="width: 100%" placeholder="Selecione"
                :options="customers" optionLabel="name" optionValue="id"
                :class="`input-autocomplete ${submitted && !form.customerId ? 'p-invalid' : ''}`" :disabled="!this.edit"
                filter />
            <p v-if="submitted && !form.customerId" class="error-message">Campo obrigatório</p>
        </div>
        <div class="col-5">
            <label for="unidade" class="input-label">Unidade *</label>
            <Dropdown id="unidade" name="unidade" v-model="form.unidadeId" style="width: 100%" placeholder="Selecione"
                :options="customerBranches" optionLabel="name" optionValue="id"
                :class="`input-autocomplete ${submitted && !form.unidadeId ? 'p-invalid' : ''}`"
                :disabled="!this.edit || !form.customerId" filter @filter="getCustomerBranches" />
            <p v-if="submitted && !form.unidadeId" class="error-message">Campo obrigatório</p>
        </div>
        <div class="col-2 pr-0">
            <label for="inicio" class="input-label">Data de Inicio *</label>
            <Calendar class="input-data" id="inicio" name="inicio" v-model="form.dataInicial" showIcon style="width: 100%"
                :class="`${submitted && !form.dataInicial ? 'p-invalid' : ''}`" :disabled="!this.edit" />
            <p v-if="submitted && !form.dataInicial" class="error-message">Campo obrigatório</p>
        </div>
    </div>
    <div class="grid">
        <div class="col-6 pl-0">
            <div>
                <label class="input-label">Tipo da ação *</label>
            </div>
            <div class="flex flex-wrap justify-content-start gap-5 mt-3">
                <div class="flex align-items-start">
                    <RadioButton v-model="form.tipo" name="epi" id="epi" value="EPI" :disabled="!this.edit" />
                    <label for="epi" class="ml-2">EPI</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton v-model="form.tipo" name="epc" id="epc" value="EPC" :disabled="!this.edit" />
                    <label for="epc" class="ml-2">EPC</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton v-model="form.tipo" name="ma" id="ma" value="ADMINISTRATIVA" :disabled="!this.edit" />
                    <label for="ma" class="ml-2">Medida Administrativa</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton v-model="form.tipo" name="me" id="me" value="ENGENHARIA" :disabled="!this.edit" />
                    <label for="me" class="ml-2">Medida de engenharia</label>
                </div>
            </div>
        </div>
        <div class="col-3" v-if="form.tipo === 'EPI'">
            <label for="epi" class="input-label">EPI * <i class="pi pi-exclamation-circle cursor-pointer"
                    style="color: #4c9ddf" @mouseover="toggleEpi" @mouseleave="toggleEpi" /></label>
            <Dropdown id="epi" v-model="form.epiId" style="width: 100%" placeholder="Selecione" :options="epis"
                optionLabel="nome" optionValue="id" class="input-autocomplete"
                :class="{ 'p-invalid': submitted && form.tipo === 'EPI' && !form.epiId }" :disabled="!this.edit" filter />
            <OverlayPanel ref="opepi">
                <p>Equipamento de proteção individual</p>
            </OverlayPanel>
            <p v-if="submitted && form.tipo === 'EPI' && !form.epiId" class="error-message">Campo obrigatório</p>
        </div>
        <div class="col-3" v-if="form.tipo === 'EPC'">
            <label for="epc" class="input-label">EPC * <i class="pi pi-exclamation-circle cursor-pointer"
                    style="color: #4c9ddf" @mouseover="toggleEpc" @mouseleave="toggleEpc" /></label>
            <Dropdown id="epc" v-model="form.epcId" style="width: 100%" placeholder="Selecione" :options="epcs"
                optionLabel="nome" optionValue="id" class="input-autocomplete" :disabled="!this.edit"
                :class="{ 'p-invalid': submitted && form.tipo === 'EPC' && !form.epcId }" filter />
            <OverlayPanel ref="opepc">
                <p>Equipamento de proteção coletiva</p>
            </OverlayPanel>
            <p v-if="submitted && form.tipo === 'EPC' && !form.epcId" class="error-message">Campo obrigatório</p>
        </div>
        <div class="col-3 pr-0" v-if="form.tipo === 'EPI'">
            <label for="certificadoAprovacao" class="input-label">CA <i class="pi pi-exclamation-circle cursor-pointer"
                    style="color: #4c9ddf" @mouseover="toggleCa" @mouseleave="toggleCa" /></label>
            <MultiSelect id="certificadoAprovacao" v-model="form.caId" style="width: 100%" placeholder="Selecione"
                :options="cas.filter((element) => element.epiId === this.form.epiId)" :optionLabel="(option) =>
                    `${option.codigo} - Atenuação: ${option.atenuacao} - Vencimento: ${option.vencimento.split('-').reverse().join('/')} ${new Date(option.vencimento) < new Date() ? '(Vencido)' : ''
                    }`
                    " class="input-multiselect" :disabled="!this.form.epiId || !this.planoAcao.status === 'CONCLUIDO'"
                @onchange="getActualCa" filter />
                <p v-if="submitted && form.tipo === 'EPC' && !form.epcId" class="error-message">Campo obrigatório</p>
                <OverlayPanel ref="opca">
                <p>Certificado de Aprovação</p>
            </OverlayPanel>
        </div>
    </div>
    <div class="grid">
        <div class="col-6 pl-0">
            <label for="nomeAcao" class="input-label">Nome da Ação *</label>
            <InputText id="nomeAcao" maxlength="50" v-model="form.nomeAcao" style="width: 100%"
                :class="{ 'p-invalid': submitted && !form.nomeAcao }" :disabled="!this.edit" />
            <p v-if="submitted && !form.nomeAcao" class="error-message">Campo obrigatório</p>
        </div>
        <div class="col-6 pr-0">
            <label for="objetivoAcao" class="input-label">Objetivo da Ação *</label>
            <Dropdown id="objetivoAcao" v-model="form.objetivoAcao" :options="objectiveActionOptions" optionLabel="label"
                optionValue="value" style="width: 100%" placeholder="Selecione" class="input-select"
                :class="{ 'p-invalid': submitted && !form.objetivoAcao }" :disabled="!this.edit" />
            <p v-if="submitted && !form.objetivoAcao" class="error-message">Campo obrigatório</p>
        </div>
    </div>
    <div class="grid">
        <div class="col-6 pl-0" style="border-radius: 50px">
            <label for="descricaoObjetivoAcao" class="input-label">Descrição do Objetivo {{ `${form.tipo ===
                'ADMINISTRATIVA' || form.tipo === 'ENGENHARIA' ? '*' : ''}` }}</label>
            <Editor v-if="this.edit" id="descricaoObjetivoAcao" v-model="form.descricaoObjetivoAcao"
                editorStyle="height: 150px;" class="mt-2" :class="{
                    'p-invalid': submitted && !form.descricaoObjetivoAcao && (form.tipo === 'ADMINISTRATIVA' || form.tipo === 'ENGENHARIA')
                }" ref="editor">
                <template v-slot:toolbar>
                    <span class="ql-formats">
                        <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                            <option value="1">Heading 1</option>
                            <option value="2">Subheading</option>
                            <option value="3">Normal</option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                            <option label="Sans Serif" value="Arial, sans-serif"></option>
                            <option label="Serif" value="'Georgia', serif"></option>
                            <option label="Monospace" value="'Courier New', monospace"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                        <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                        <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                        <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                        <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                            <option value=""></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                        <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                    </span>
                </template>
            </Editor>
            <Editor v-else id="descricaoObjetivoAcao" v-model="form.descricaoObjetivoAcao" editorStyle="height: 150px;"
                class="mt-2" :class="{
                    'p-invalid': submitted && !form.descricaoObjetivoAcao && (form.tipo === 'ADMINISTRATIVA' || form.tipo === 'ENGENHARIA')
                }" readonly>
                <template v-slot:toolbar>
                    <span class="ql-formats">
                        <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                            <option value="1">Heading 1</option>
                            <option value="2">Subheading</option>
                            <option value="3">Normal</option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                            <option label="Sans Serif" value="Arial, sans-serif"></option>
                            <option label="Serif" value="'Georgia', serif"></option>
                            <option label="Monospace" value="'Courier New', monospace"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                        <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                        <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                        <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                        <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                            <option value=""></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                        <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                    </span>
                </template>
            </Editor>
            <p v-if="submitted && !form.descricaoObjetivoAcao && (form.tipo === 'ADMINISTRATIVA' || form.tipo === 'ENGENHARIA')"
                class="error-message">
                Campo obrigatório
            </p>
        </div>
        <div class="col-6 pr-0">
            <label for="descricaoPlanejamentoExecucao" class="input-label">Descrição do planejamento de execução *</label>
            <Editor v-if="this.edit" id="descricaoPlanejamentoExecucao" v-model="form.descricaoPlanejamentoExecucao"
                editorStyle="height: 150px" class="mt-2" type="button"
                :class="{ 'p-invalid': submitted && !form.descricaoPlanejamentoExecucao }">
                <template v-slot:toolbar>
                    <span class="ql-formats">
                        <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                            <option value="1">Heading 1</option>
                            <option value="2">Subheading</option>
                            <option value="3">Normal</option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                            <option label="Sans Serif" value="Arial, sans-serif"></option>
                            <option label="Serif" value="'Georgia', serif"></option>
                            <option label="Monospace" value="'Courier New', monospace"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                        <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                        <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                        <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                        <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                            <option value=""></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                        <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                    </span>
                </template>
            </Editor>

            <Editor v-else id="descricaoPlanejamentoExecucao" v-model="form.descricaoPlanejamentoExecucao"
                editorStyle="height: 150px" class="mt-2" type="button"
                :class="{ 'p-invalid': submitted && !form.descricaoPlanejamentoExecucao }" readonly>
                <template v-slot:toolbar>
                    <span class="ql-formats">
                        <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                            <option value="1">Heading 1</option>
                            <option value="2">Subheading</option>
                            <option value="3">Normal</option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                            <option label="Sans Serif" value="Arial, sans-serif"></option>
                            <option label="Serif" value="'Georgia', serif"></option>
                            <option label="Monospace" value="'Courier New', monospace"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                        <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                        <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                        <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                        <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                            <option value=""></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                        <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                    </span>
                    <span class="ql-formats">
                        <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                    </span>
                </template>
            </Editor>
            <p v-if="submitted && !form.descricaoPlanejamentoExecucao" class="error-message">Campo obrigatório</p>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 px-0 flex justify-content-end">
            <Button label="Voltar" @click="$router.push('/plano-acao/listar')" icon="pi pi-home" iconPos="left"
                type="button" class="mr-2" />
            <Button :label="this.$route.params.id ? 'Editar' : 'Salvar'" @click="save()" icon="pi pi-save" iconPos="left"
                type="button" :disabled="!this.edit" :loading="loading" />
        </div>
    </div>
    <Dialog v-model:visible="confirmNewDate" modal :style="{ width: '40vw' }">
        <h4>A data de conclusão será alterada em decorrência da alteração de data de inicio. Continuar?</h4>
        <template #footer>
            <Button label="Cancelar" @click="() => {
                confirmNewDate = false;
                this.form.dataInicial = this.planoAcao.dataInicial;
            }
                " class="p-button-outlined mr-4 b-rounded" />
            <Button label="Confirmar" @click="saveNewDate" class="b-rounded" />
        </template>
    </Dialog>
    <Dialog v-model:visible="caUpdateJustify" :closable="false" class="p-dialog-no-header">
        <div class="remove-dialog p-5 pt-0">
            <p>Justifique a alteração de Certificado de Aprovação</p>
        </div>

        <Textarea class="w-full" v-model="caJustify" rows="4" placeholder="Justificar" />

        <template #footer>
            <Button label="Justificar" icon="pi pi-cross" @click="justifyCa" class="b-rounded" />
        </template>
    </Dialog>
</template>

<script>
import PlanoAcaoService from '../../../services/planoAcaoService';
import BaseService from '../../../services/BaseService';
import moment from 'moment-timezone';

export default {
    props: {
        status: {
            type: String
        }
    },
    data() {
        return {
            id: '',
            loading: false,
            form: {
                customerId: '',
                unidadeId: '',
                dataInicial: new Date(),
                tipo: 'EPI',
                nomeAcao: '',
                objetivoAcao: '',
                descricaoObjetivoAcao: '',
                descricaoPlanejamentoExecucao: '',
                status: 'PENDENTE',
                epiId: '',
                epcId: '',
                caId: ''
            },
            confirmNewDate: false,
            updateNewDate: false,
            caUpdateJustify: false,
            caJustify: '',
            customer: {},
            unidade: {},
            ca: {},
            submitted: false,
            objectiveActionOptions: [
                {
                    value: 'REDUZIR',
                    label: 'Reduzir'
                },
                {
                    value: 'ELIMINAR',
                    label: 'Eliminar'
                },
                {
                    value: 'CONTROLAR',
                    label: 'Controlar'
                }
            ],
            customers: [],
            customerBranches: [],
            epis: [],
            epcs: [],
            cas: [],
            planoAcaoId: '',
            edit: false,
            planoAcao: {},
            queryDelay: 250
        };
    },
    async mounted() {
        this.$service = new PlanoAcaoService();
        await this.getCustomers();
        await this.getEpis();
        await this.getEpcs();
        await this.getCas();
        if (this.$route.params.id) {
            this.edit = !!this.$route.query.edit;
            const { data } = await this.$service.get('', '', this.$route.params.id);
            const ca = data.ca;

            this.planoAcao = { ...data };

            this.form = { ...data, dataInicial: moment(data.dataInicial).format('DD/MM/YYYY') };
            this.unidade = this.form.unidade;
            this.form.unidadeId = this.unidade.id;
            this.customer = this.form.customer;

            if (data.epiId) this.form.epiId = data.epiId;
            if (data.epcId) this.form.epcId = data.epcId;
            if (ca.length) this.form.caId = ca;
        } else {
            this.edit = true;
        }
    },
    methods: {
        async justifyCa() {
            this.loading = false;

            await new BaseService('linha-do-tempo').save({
                planoAcaoId: this.planoAcao.id,
                companyId: this.planoAcao.companyId,
                origem: 'O campo Certificado de Aprovação foi alterado.',
                status: 3,
                descricao: `Justificativa: ${this.caJustify}`
            });

            this.save();
        },
        toggleEpi(event) {
            this.$refs.opepi.toggle(event);
        },
        toggleEpc(event) {
            this.$refs.opepc.toggle(event);
        },
        toggleCa(event) {
            this.$refs.opca.toggle(event);
        },
        async save() {
            this.submitted = true;
            this.loading = true;

            if (this.planoAcao.status === 'EM_ANDAMENTO' && !this.caUpdateJustify) {
                if (this.planoAcao.ca.length !== this.form.caId?.length) {
                    return (this.caUpdateJustify = true);
                }

                if (JSON.stringify(this.planoAcao.ca) !== JSON.stringify(this.form.caId)) {
                    await Promise.all(
                        this.planoAcao.ca.forEach(async (element) => {
                            if (!this.form.caId.map((el) => el.id).includes(element.id)) {
                                return (this.caUpdateJustify = true);
                            }
                        })
                    );
                }
            }

            console.log('entrou');

            if (
                this.planoAcao.dataPrevisao &&
                !this.form.dataInicial.length &&
                new Date(this.planoAcao.dataInicial) !== new Date(this.form.dataInicial)
            ) {
                return (this.confirmNewDate = true);
            }

            console.log('entrou1');

            if (this.form.tipo !== 'EPI' && this.form.tipo !== 'EPC') {
                if (!this.form.descricaoObjetivoAcao) {
                    this.loading = false;
                    this.caUpdateJustify = false;
                    console.log('entrou2');
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Descrição do objetivo obrigatória para esse tipo de plano de ação.',
                        life: 3000
                    });
                }
            }

            console.log('entrou3');

            const dataToSave = {
                customerId: Number(this.form.customerId),
                unidadeId: Number(this.form.unidadeId),
                dataInicial: this.form.dataInicial.length ? this.planoAcao.dataInicial : this.form.dataInicial,
                tipo: this.form.tipo,
                nomeAcao: this.form.nomeAcao,
                objetivoAcao: this.form.objetivoAcao,
                descricaoObjetivoAcao: this.form.descricaoObjetivoAcao,
                descricaoPlanejamentoExecucao: this.form.descricaoPlanejamentoExecucao,
                status: this.form.status
            };

            if (this.form.epiId) {
                dataToSave.epiId = this.form.epiId;
            }
            if (this.form.epcId) {
                dataToSave.epcId = this.form.epcId;
            }
            if (this.form.caId?.length) {
                dataToSave.ca = this.form.caId;
            }

            try {
                if (this.edit && this.$route.params.id) {
                    await this.$service.patch(this.$route.params.id, dataToSave).then((res) => {
                        this.$emit('updatePlan', res.data.id);
                        this.submitted = false;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Plano de Ação atualizado com sucesso.',
                            life: 3000
                        });
                        this.id = res.data.id;
                    });
                } else {
                    await this.$service.post(dataToSave).then((res) => {
                        this.$emit('updatePlan', res.data.id);
                        this.submitted = false;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Plano de Ação criado. Complete seu cadastro navegando pelas abas.',
                            life: 3000
                        });
                        this.id = res.data.id;
                    });
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro na criação/atualização do plano de ação', life: 3000 });
                this.loading = false;
                this.caUpdateJustify = false;
            }

            console.log('entrou4');
            this.$router.push({ name: 'Editar', params: { id: this.id }, query: { edit: true } });

            this.loading = false;
            this.caUpdateJustify = false;
        },
        async saveNewDate() {
            this.submitted = true;
            this.loading = true;

            if (this.form.tipo !== 'EPI' && this.form.tipo !== 'EPC') {
                if (!this.form.descricaoObjetivoAcao) {
                    this.loading = false;
                    this.confirmNewDate = false;

                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Descrição do objetivo obrigatória para esse tipo de plano de ação.',
                        life: 3000
                    });
                }
            }

            const now = moment(this.form.dataInicial);

            const dataToSave = {
                customerId: Number(this.form.customerId),
                unidadeId: Number(this.form.unidadeId),
                dataInicial: this.form.dataInicial,
                dataPrevisao: now.add(this.planoAcao.prazoConclusao, 'days').toDate(),
                tipo: this.form.tipo,
                nomeAcao: this.form.nomeAcao,
                objetivoAcao: this.form.objetivoAcao,
                descricaoObjetivoAcao: this.form.descricaoObjetivoAcao,
                descricaoPlanejamentoExecucao: this.form.descricaoPlanejamentoExecucao,
                status: this.form.status
            };

            if (this.form.epiId) {
                dataToSave.epiId = this.form.epiId;
            }
            if (this.form.epcId) {
                dataToSave.epcId = this.form.epcId;
            }
            if (this.form.caId?.length) {
                dataToSave.ca = this.form.caId;
            }

            try {
                if (this.edit && this.$route.params.id) {
                    await this.$service.patch(this.$route.params.id, dataToSave).then((res) => {
                        this.$emit('updatePlan', res.data.id);
                        this.submitted = false;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Plano de Ação atualizado com sucesso.',
                            life: 3000
                        });
                        this.id = res.data.id;
                    });
                } else {
                    await this.$service.post(dataToSave).then((res) => {
                        this.$emit('updatePlan', res.data.id);
                        this.submitted = false;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Plano de Ação criado. Complete seu cadastro navegando pelas abas.',
                            life: 3000
                        });
                        this.id = res.data.id;
                    });
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro na criação/atualização do plano de ação', life: 3000 });
                this.confirmNewDate = false;
                this.loading = false;
            }
            this.$router.push({ name: 'Editar', params: { id: this.id }, query: { edit: true } });

            this.confirmNewDate = false;
            this.loading = false;
        },
        async getCustomers() {
            const { data } = await new BaseService('/customers/combo').findAll({
            });
            this.customers = data;
        },
        async getActualCustomer() {
            const data = this.customers.find((customer) => {
                return customer.id == this.form.customerId;
            });
            this.customer = data;
        },
        async getCustomerBranches(event) {
            this.filter = event.value;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
            }, this.queryDelay);

            const { data } = await new BaseService(`/customer/branches`).findAll({
                params: {
                    customerId: this.form?.customerId ?? 0,
                    filter: event.value
                }
            });
            this.customerBranches = data?.items;
        },
        async getEpis() {
            const { data } = await new BaseService(`/epi`).findAll({ limit: 100000 });
            this.epis = data.items;
        },
        async getEpcs() {
            const { data } = await new BaseService(`/epc`).findAll({ limit: 100000 });
            this.epcs = data.items;
        },
        async getCas() {
            const { data } = await new BaseService(`/certificado-aprovacao`).findAll({});
            this.cas = data;
        },
        getActualCa() {
            this.ca = this.cas.find((element) => element.id == this.form.caId);
        }
    },
    watch: {
        'form.customerId': async function () {
            this.getActualCustomer();

            if (this.form.customerId) {
                await this.getCustomerBranches();
            }
        },
        'form.epiId': function () {
            this.getCas();
        }
    }
};
</script>

<style scoped>
.input-autocomplete,
.p-inputtext,
.input-data,
.input-select {
    margin-top: 8px;
    height: 38px;
    border-radius: 4px;
}

.input-multiselect {
    margin-top: 8px;
    height: 38px;
    border-radius: 4px;
    align-items: center;
}

.error-message {
    color: red;
    font-size: x-small;
    margin-top: 2px;
}
</style>
