<template>
    <div class="px-0 flex flex-column" :class="`${loading && 'pointer-events-none'}`" style="height: 100%">
        <h4 class="mt-4">Pessoas envolvidas</h4>
        <DataTable dataKey="id" :value="envolvidos" responsiveLayout="scroll" breakpoint="640px" :rows="10" style="cursor: pointer" class="mb-3">
            <template #empty>Nenhum registro encontrado.</template>
            <template #loading>Carregando registros. Aguarde...</template>
            <Column field="tipoEnvolvido" header="Tipo" style="width: 27%">
                <template #body="props">
                    <Dropdown
                        :id="props.field"
                        v-model="props.data[props.field]"
                        :options="tipoEnvolvidoOptions"
                        optionLabel="label"
                        optionValue="value"
                        style="width: 100%"
                        class="input-select"
                        v-if="props.data.edit"
                    />
                    <label class="status-display" v-else>
                        {{
                            props.data?.tipoEnvolvido === 'USUARIO'
                                ? 'Usuário'
                                : props.data?.tipoEnvolvido === 'FUNCIONARIO'
                                ? 'Funcionário'
                                : props.data?.tipoEnvolvido === 'AVULSO'
                                ? 'Avulso'
                                : ''
                        }}
                    </label>
                </template>
            </Column>
            <Column field="nomeEnvolvido" header="Nome" style="width: 27%">
                <template #body="props">
                    <Dropdown
                        :id="props.field"
                        v-model="props.data[props.field]"
                        :options="props.data.tipoEnvolvido === 'FUNCIONARIO' ? funcionarios : usuarios"
                        optionLabel="name"
                        optionValue="name"
                        style="width: 100%"
                        class="input-select"
                        v-if="props.data.edit && props.data.tipoEnvolvido !== 'AVULSO'"
                        filter
                    />
                    <InputText
                        :id="props.field"
                        v-model="props.data[props.field]"
                        style="width: 100%"
                        :min="0"
                        class="input-select"
                        v-if="props.data.edit && props.data.tipoEnvolvido === 'AVULSO'"
                    />
                    <label v-if="!props.data.edit">
                        {{ props.data?.nomeEnvolvido }}
                    </label>
                </template>
            </Column>
            <Column field="responsavel" header="Responsável" style="width: 8%">
                <template #body="props">
                    <div class="flex align-items-center gap-2">
                        <Checkbox
                            :id="props.field"
                            :disabled="!props.data.edit"
                            v-model="props.data.responsavel"
                            :binary="Boolean(props.data?.responsavel)"
                        />
                        <label>Sim</label>
                    </div>
                </template>
            </Column>
            <Column field="descricaoResponsabilidade" header="Responsabilidade" style="width: 27%">
                <template #body="props">
                    <InputText :min="0" :id="props.field" v-model="props.data[props.field]" style="width: 100%" v-if="props.data.edit" />
                    <label v-else>
                        {{ props.data?.descricaoResponsabilidade }}
                    </label>
                </template>
            </Column>
            <Column field="" bodyClass="text-right" header="" style="width: 4%">
                <template #body="props">
                    <Button
                        v-if="!props.data.edit"
                        icon="pi pi-ellipsis-v"
                        class="p-button-text p-button-secondary"
                        @click="toggleMenu($event, props)"
                    />
                    <div class="flex" v-else>
                        <Button
                            icon="pi pi-times"
                            class="p-button-text p-button-secondary"
                            @click="
                                () => {
                                    const envolvidoOriginal = this.envolvidosOriginal.find((_, index) => index === props.index);

                                    if (!envolvidoOriginal) {
                                        return this.handleRemoveEnvolvido();
                                    }

                                    props.data.tipoEnvolvido = envolvidoOriginal.tipoEnvolvido;
                                    props.data.nomeEnvolvido = envolvidoOriginal.nomeEnvolvido;
                                    props.data.responsavel = envolvidoOriginal.responsavel;
                                    props.data.descricaoResponsabilidade = envolvidoOriginal.descricaoResponsabilidade;
                                    props.data.edit = false;
                                }
                            "
                        />
                    </div>
                </template>
            </Column>
            <template #footer>
                <div class="flex justify-content-between">
                    Total pessoas envolvidas <span class="pr-3">{{ this.envolvidos.length }}</span>
                </div>
            </template>
            <Menu ref="menu" :model="actionItems" :popup="true" />
        </DataTable>
        <div>
            <Button text label="Adicionar pessoa" icon="pi pi-plus" class="p-button-text" @click="addEnvolvido" />
        </div>
        <div class="flex justify-content-end" style="margin-top: auto">
            <Button label="Voltar" @click="$router.push('/plano-acao/listar')" icon="pi pi-home" iconPos="left" type="button" class="mr-2" />
            <Button
                label="Salvar"
                @click="save"
                icon="pi pi-save"
                iconPos="left"
                :disabled="this.planoAcao.status !== 'PENDENTE'"
                :loading="loading"
            />
        </div>
    </div>
</template>

<script>
import PlanoAcaoEnvolvidosService from '../../../services/planoAcaoEnvolvidosService';
import BaseService from '../../../services/BaseService';
import Capitalize from '../../../utils/Capitalize';

export default {
    data() {
        return {
            usuarios: [],
            funcionarios: [],
            envolvidos: [],
            envolvidosOriginal: [],
            selectedEnvolvido: {},
            submitted: false,
            loading: false,
            planoAcao: {},
            tipoEnvolvidoOptions: [
                { value: 'USUARIO', label: 'Usuário' },
                { value: 'FUNCIONARIO', label: 'Funcionário' },
                { value: 'AVULSO', label: 'Avulso' }
            ],
            actionItems: [
                {
                    label: () => (this.selectedEnvolvido.data.edit ? 'Salvar' : 'Alterar'),
                    icon: 'pi pi-pencil',
                    command: () => this.handleSetEditMode()
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.handleRemoveEnvolvido()
                }
            ]
        };
    },
    async mounted() {
        this.$service = new PlanoAcaoEnvolvidosService();
        this.$capitalize = new Capitalize();
        if (this.$route.params.id) {
            await this.getEnvolvidos();
            await this.getUsuarios();
            const { data: planoAcao } = await new BaseService('/plano-acao').findById(this.$route.params.id);
            this.planoAcao = planoAcao;
            await this.getFuncionarios();
        }
    },
    methods: {
        async save() {
            this.submitted = true;
            this.loading = true;

            const { data } = await this.$service.get(`combo-plano-acao/${this.$route.params.id}`);

            const originalIds = data.map((element) => element.id);
            const newIds = this.envolvidos.map((element) => element.id);

            originalIds.forEach(async (id) => {
                if (id.length > 7) {
                    if (!newIds.includes(id)) {
                        const selected = data.find((element) => element.id === id);

                        if (selected.responsavel) {
                            await this.getEnvolvidos();

                            return this.$toast.add({
                                severity: 'error',
                                summary: 'Não é possível excluir o envolvido responsável, re-atribua a responsabilidade antes de excluir.',
                                life: 3000
                            });
                        }

                        await this.$service.delete(id).then(() => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Envolvido removido.',
                                life: 3000
                            });
                        });
                    }
                }
            });

            this.envolvidos.forEach(async (envolvido, index, array) => {
                if (!this.envolvidos.some((element) => element.responsavel)) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Selecione um responsável.',
                        life: 3000
                    });
                }

                try {
                    if (envolvido.id.length > 7) {
                        const envolvidoSelecionado = data.find((element) => element.id === envolvido.id);

                        if (
                            envolvido.tipoEnvolvido === envolvidoSelecionado.tipoEnvolvido &&
                            envolvido.responsavel === envolvidoSelecionado.responsavel &&
                            envolvido.descricaoResponsabilidade === envolvidoSelecionado.descricaoResponsabilidade
                        ) {
                            return;
                        }

                        const dataToSave = {
                            tipoEnvolvido: envolvido.tipoEnvolvido,
                            responsavel: envolvido.responsavel,
                            descricaoResponsabilidade: envolvido.descricaoResponsabilidade
                        };

                        await this.$service.patch(envolvido.id, dataToSave).then((res) => {
                            this.envolvidos[index] = res.data;
                            this.envolvidosOriginal[index] = res.data;
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Envolvido editado.',
                                life: 3000
                            });
                        });
                    } else {
                        const envolvidoSelecionado =
                            envolvido.tipoEnvolvido === 'USUARIO'
                                ? this.usuarios.find((element) => element.name === envolvido.nomeEnvolvido)
                                : this.funcionarios.find((element) => element.name === envolvido.nomeEnvolvido);

                        let doubleCheck = false;

                        const isFirst = index === array.findIndex((prevItem) => prevItem.nomeEnvolvido === envolvido.nomeEnvolvido);

                        if (!isFirst) {
                            this.envolvidos.forEach((element) => {
                                if (
                                    element.nomeEnvolvido === envolvidoSelecionado.name &&
                                    element.tipoEnvolvido === envolvido.tipoEnvolvido &&
                                    !isFirst
                                ) {
                                    doubleCheck = true;
                                }
                            });
                        }

                        if (doubleCheck) {
                            return this.$toast.add({
                                severity: 'error',
                                summary: 'Usuário já cadastrado.',
                                life: 3000
                            });
                        }

                        const dataToSave = {
                            planoAcaoId: this.$route.params.id,
                            tipoEnvolvido: envolvido.tipoEnvolvido,
                            responsavel: Boolean(envolvido.responsavel),
                            descricaoResponsabilidade: envolvido.descricaoResponsabilidade,
                            envolvidoId: envolvidoSelecionado ? envolvidoSelecionado.id : null,
                            nomeEnvolvido: envolvido.nomeEnvolvido
                        };

                        await this.$service.post(dataToSave).then((res) => {
                            this.envolvidos[index] = res.data;
                            this.envolvidosOriginal[index] = res.data;
                            data.push(res.data);
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Envolvido cadastrado.',
                                life: 3000
                            });
                        });
                    }
                    this.loading = false;
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Erro no cadastro de envolvidos', life: 3000 });
                }
            });
        },
        async getUsuarios() {
            const { data } = await new BaseService('/users/combo').findAll({});
            this.usuarios = data;
        },
        async getFuncionarios() {
            const { data } = await new BaseService('customer/employees/by-customer').findAll({ customerId: this.planoAcao.customerId });
            this.funcionarios = data;
        },
        async getEnvolvidos() {
            const { data } = await this.$service.get(`combo-plano-acao/${this.$route.params.id}`);
            this.envolvidos = data;
            this.envolvidosOriginal = [...data];
        },
        ensureSingleResponsability(indexSelecionado) {
            this.envolvidos = this.envolvidos.map((element, index) => {
                return index === indexSelecionado ? { ...element, responsavel: true } : { ...element, responsavel: false };
            });
        },
        addEnvolvido() {
            const novoEnvolvido = { id: `${Math.floor(Math.random() * (100000 - 0 + 1)) + 0}`, edit: true, index: this.envolvidos.length };

            this.selectedEnvolvido = novoEnvolvido;
            this.envolvidos.push(novoEnvolvido);
        },
        handleSetEditMode() {
            if (this.envolvidos[this.selectedEnvolvido.index].edit) {
                if (
                    !this.envolvidos[this.selectedEnvolvido.index].tipoEnvolvido ||
                    !this.envolvidos[this.selectedEnvolvido.index].descricaoResponsabilidade ||
                    !this.envolvidos[this.selectedEnvolvido.index].nomeEnvolvido
                ) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Alguns campos não foram preenchidos!',
                        life: 3000
                    });
                }
            }

            if (this.envolvidos[this.selectedEnvolvido.index].edit && this.envolvidos[this.selectedEnvolvido.index].responsavel)
                this.ensureSingleResponsability(this.selectedEnvolvido.index);

            this.envolvidos[this.selectedEnvolvido.index] = {
                ...this.envolvidos[this.selectedEnvolvido.index],
                edit: !this.envolvidos[this.selectedEnvolvido.index].edit
            };
        },
        handleRemoveEnvolvido() {
            this.envolvidos.splice(this.selectedEnvolvido.index, 1);
        },
        toggleMenu(event, data) {
            this.selectedEnvolvido = data;
            this.$refs.menu.toggle(event);
        }
    },
    watch: {}
};
</script>

<style scoped></style>
