<template>
    <div class="grid" :class="`${loading && 'pointer-events-none'}`" style="height: 100%">
        <div class="col-12 pb-0" v-if="!planoDeAcao.matrizConfigId">
            <div class="flex align-items-center pt-2 pb-2">
                <InputSwitch v-model="riskFactor" class="mr-2" :disabled="!this.edit" />
                <h5 class="m-0">A causa tem relação com um perigo/fator de risco?</h5>
            </div>
        </div>
        <div class="col-12" v-if="!planoDeAcao.matrizConfigId && riskFactor" style="min-height: 60px">
            <Button class="p-button-text pl-1" @click="showModal = true"> <i class="pi pi-plus mr-2" /> Adicionar Matriz de
                risco </Button>
        </div>
        <div class="col-12 pb-0">
            <Dialog v-model:visible="showModal" modal :style="{ width: '40vw' }">
                <template #header>
                    <h4>Selecionar Matriz de risco</h4>
                </template>

                <label for="matriz" class="input-label">Matriz de aval. de Risco *</label>
                <Dropdown id="matriz" name="matriz" v-model="matriz" placeholder="Selecione" style="width: 100%"
                    optionLabel="nome" :options="matrizes" :class="submitted && matriz ? 'p-invalid mt-1' : 'mt-1'"
                    filter />

                <template #footer>
                    <Button label="Cancelar" @click="showModal = false" class="p-button-outlined mr-4 b-rounded" />
                    <Button label="Confirmar" @click="selecionarMatriz" class="b-rounded" />
                </template>
            </Dialog>
        </div>
        <div class="col-12 pt-0 pb-0 table-container">
            <div class="flex align-items-center justify-content-between" v-if="planoDeAcao.matrizConfigId">
                <h3>Perigo/Fator de Risco selecionado</h3>
                <p>Matriz de risco selecionada: {{ this.matriz?.nome }}</p>
            </div>
            <DataTable dataKey="id" responsiveLayout="scroll" breakpoint="640px" style="cursor: pointer" class="table"
                v-if="planoDeAcao.matrizConfigId" :row-hover="true" :rows="10" :value="causas">
                <template #empty>Nenhum registro encontrado.</template>
                <template #loading>Carregando registros. Aguarde...</template>
                <Column header="Grupo" style="width: 16%">
                    <template #body="row">
                        <Dropdown id="Grupo" v-model="row.data.grupoRisco" style="width: 100%" placeholder="Selecione"
                            optionLabel="nome" optionValue="valor" class="input-autocomplete" v-if="row.data.edit" filter
                            :options="grupos" @change="() => {
                                row.data.risco = '';
                                row.data.riscoDescRisco = '';
                            }
                                " />
                        <p v-else>
                            {{ gruposOpcoes[row.data.grupoRisco] }}
                        </p>
                    </template>
                </Column>
                <Column header="Perigo/Fator de Risco" style="width: 16%">
                    <template #body="row">
                        <Dropdown id="risco" v-model="row.data.risco" style="width: 100%" placeholder="Selecione"
                            optionLabel="nome" class="input-autocomplete" v-if="row.data.edit" filter :options="riscos.filter((element) => {
                                return element.grupo_risco === row.data.grupoRisco;
                            })
                                " @change="() => updateRiskDependencies(row)" :disabled="!row.data.grupoRisco" />
                        <p v-else>
                            {{ row.data.risco?.nome }}
                        </p>
                    </template>
                </Column>
                <Column header="Descrição de Risco*" style="width: 16%">
                    <template #body="row">
                        <Dropdown id="Descrição de Risco" v-model="row.data.riscoDescRisco" style="width: 100%"
                            placeholder="Selecione" optionLabel="nome" class="input-autocomplete" v-if="row.data.edit"
                            filter :options="descriptions
                                .filter((element) => {
                                    return element.riscoDescricaoRisco.map((el) => el.riscoId).includes(row.data.risco?.id);
                                })
                                .map((element) => {
                                    const { riscoDescricaoRisco, ...rest } = element;

                                    return rest;
                                })
                                " @change="() => updateRiskDependencies(row)" :disabled="!row.data.risco" />
                        <p v-else>
                            {{ row.data?.riscoDescRisco?.nome }}
                        </p>
                    </template>
                </Column>
                <Column :header="matriz.nomeColuna" style="width: 16%">
                    <template #body="row">
                        <Dropdown id="eixoXMatriz" v-model="row.data.eixoXMatriz" placeholder="Selecione"
                            style="width: 100%" optionLabel="titulo" v-if="row.data.edit" filter :options="linhas"
                            @change="row.data.resultadoMatriz = this.getResultado(row.data.eixoXMatriz?.id, row.data.eixoYMatriz?.id)?.titulo" />
                        <p v-else>
                            {{ typeof row.data.eixoXMatriz !== 'string' ? row.data.eixoXMatriz?.titulo :
                                getLinhaAtual(row.data.eixoXMatriz) }}
                        </p>
                    </template>
                </Column>
                <Column :header="matriz.nomeLinha" style="width: 16%">
                    <template #body="row">
                        <Dropdown id="eixoXMatriz" v-model="row.data.eixoYMatriz" placeholder="Selecione"
                            style="width: 100%" optionLabel="titulo" v-if="row.data.edit" filter :options="colunas"
                            @change="row.data.resultadoMatriz = this.getResultado(row.data.eixoXMatriz?.id, row.data.eixoYMatriz?.id)?.titulo" />
                        <p v-else>
                            {{ typeof row.data.eixoYMatriz !== 'string' ? row.data.eixoYMatriz?.titulo :
                                getColunaAtual(row.data.eixoYMatriz) }}
                        </p>
                    </template>
                </Column>
                <Column :header="matriz.nomeResultado" style="width: 16%">
                    <template #body="row">
                        <p class="risk-level" :style="`background-color: ${this.grupoCores?.find(
                            (element) => element.id === getResultado(row.data.eixoXMatriz?.id, row.data.eixoYMatriz?.id)?.grupoId
                        )?.cor ?? '#ffffff'
                            }`">
                            {{
                                typeof row.data.eixoYMatriz !== 'string'
                                ? getResultado(row.data.eixoXMatriz?.id, row.data.eixoYMatriz?.id)?.titulo
                                : getResultado(row.data.eixoXMatriz, row.data.eixoYMatriz)?.titulo
                            }}
                        </p>
                    </template>
                </Column>

                <Column field="" bodyClass="text-right" header="" style="width: 4%">
                    <template #body="row">
                        <div class="flex">
                            <Button v-if="row.data.edit" icon="pi pi-times" class="p-button-text p-button-secondary" @click="() => {
                                const causaOriginal = this.causasOriginais.find((_, index) => {
                                    return index === row.index;
                                });

                                if (!causaOriginal || Object.entries(causaOriginal).length <= 2) {
                                    return this.deletePlan(row.index);
                                }

                                row.data.grupoRisco = causaOriginal.grupoRisco;
                                row.data.risco = causaOriginal.risco;
                                row.data.riscoDescRisco = causaOriginal.riscoDescRisco;
                                row.data.eixoXMatriz = causaOriginal.eixoXMatriz;
                                row.data.eixoYMatriz = causaOriginal.eixoYMatriz;
                                row.data.resultadoMatriz = this.getResultado(
                                    causaOriginal.eixoXMatriz?.id,
                                    causaOriginal.eixoYMatriz?.id
                                )?.titulo;

                                row.data.edit = false;
                            }
                                " />
                            <Button v-if="!row.data.edit" icon="pi pi-ellipsis-v"
                                :class="`p-button-text p-button-secondary`" @click="(e) => toggleMenu(e, row.data)" />
                        </div>
                    </template>
                </Column>
                <Menu ref="menu" :model="actionItems" :popup="true" />
            </DataTable>

            <div class="col-12" style="min-height: 60px">
                <Button class="p-button-text pl-1" v-if="planoDeAcao.matrizConfigId" @click="adicionarPerigo">
                    <i class="pi pi-plus mr-2" /> Adicionar perigo/fator de risco
                </Button>
                <Button class="p-button-text pl-1" v-if="planoDeAcao.matrizConfigId && !causas.length"
                    @click="removerMatriz">
                    <i class="pi pi-minus mr-2" /> Remover matriz
                </Button>
            </div>
        </div>
        <div class="col-12 grid p-0 w-100" style="height: 170px grid-row-end: auto">
            <div class="col-8">
                <label for="descricaoCausa" class="input-label">Descrição {{ !this.planoDeAcao.matrizConfigId ? '*' : ''
                }}</label>
                 <p v-if="submitted && !planoDeAcao.descricaoCausa" class="error-message">*Campo obrigatório</p>
                <Editor id="descricaoCausa" name="descricaoCausa" v-model="planoDeAcao.descricaoCausa"
                    editorStyle="width: 100%; height: 6rem" class="mt-1"
                    :class="submitted && !planoDeAcao.descricaoCausa ? 'p-invalid mt-1' : 'mt-1'" :readonly="!this.edit">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                                <option value="1">Heading 1</option>
                                <option value="2">Subheading</option>
                                <option value="3">Normal</option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                                <option label="Sans Serif" value="Arial, sans-serif"></option>
                                <option label="Serif" value="'Georgia', serif"></option>
                                <option label="Monospace" value="'Courier New', monospace"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                            <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                            <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                                <option value=""></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="col-4 grid px-0">
                <div class="col-12 pr-0">
                    <label for="Prioridade" class="input-label">Prioridade *</label>
                    <Dropdown id="Prioridade" name="Prioridade" v-model="planoDeAcao.prioridadeCausa" style="width: 100%"
                        placeholder="Selecione" optionLabel="nome" optionValue="value" filter :options="prioridades"
                        :class="submitted && !planoDeAcao.prioridadeCausa ? 'p-invalid mt-1' : 'mt-1'"
                        :disabled="!this.edit" />
                    <p v-if="submitted && planoDeAcao.prioridadeCausa" class="error-message">Campo obrigatório</p>
                </div>
                <div class="col-6 pr-0 flex flex-column justify-content-end">
                    <label for="Prazo" class="input-label">Prazo de conclusão {{ riskFactor ? '' : '*' }}
                        <i class="pi pi-exclamation-circle cursor-pointer" style="color: #4c9ddf" @mouseover="toggleDays"
                            @mouseleave="toggleDays" /></label>
                    <InputNumber id="Prazo" name="Prazo" v-model="planoDeAcao.prazoConclusao" style="width: 100%"
                    :class="submitted && !planoDeAcao.prazoConclusao ? 'p-invalid mt-1' : 'mt-1'" inputId="minmax" :min="0" :max="9999" @blur="addDays" :disabled="!this.edit" />
                    <OverlayPanel ref="opdc">
                        <p>Em dias corridos</p>
                    </OverlayPanel>
                </div>
                <div class="col-6 pr-0 flex flex-column justify-content-end">
                    <label for="previsao" class="input-label">Previsão de conclusão {{ riskFactor ? '' : '*' }}</label>
                    <Calendar class="input-data mt-1" id="previsao" name="previsao" v-model="planoDeAcao.dataPrevisao"
                        showIcon style="width: 100%" :class="submitted && !planoDeAcao.dataPrevisao ? 'p-invalid' : ''"
                        :disabled="!this.edit"
                        @blur="getDaysDifference(planoDeAcao.dataInicial, planoDeAcao.dataPrevisao)" />
                </div>
            </div>
        </div>
        <div class="col-12 px-0 flex justify-content-end" style="height: 60px">
            <Button label="Voltar" @click="$router.push('/plano-acao/listar')" icon="pi pi-home" iconPos="left"
                type="button" class="mr-2" />
            <Button label="Salvar" @click="save" icon="pi pi-save" iconPos="left"
                :disabled="this.planoDeAcao?.status !== 'PENDENTE'" :loading="loading" />
        </div>
    </div>
</template>

<script>
import PlanoAcaoCausaService from '../../../services/planoAcaoCausaService';
import PlanoAcaoService from '../../../services/planoAcaoService';
import moment from 'moment-timezone';

export default {
    props: {
        status: {
            type: String
        }
    },
    data() {
        return {
            edit: true,
            loading: false,
            riskFactor: false,
            submitted: false,
            showModal: false,
            planoDeAcao: {},
            matriz: {},
            causas: [],
            causasOriginais: [],
            selectedCausa: {},
            riscos: [],
            matrizes: [],
            linhas: [],
            colunas: [],
            possiveisResultados: [],
            grupoCores: [],
            gruposOpcoes: {
                1: 'Físico',
                2: 'Químico',
                3: 'Biológico',
                4: 'Ergonômico',
                5: 'Acidente Mecânico',
                6: 'Outro'
            },
            prioridadeOpcoes: [
                {
                    value: 'BAIXA',
                    label: 'Baixa'
                },
                {
                    label: 'Moderada',
                    value: 'MODERADA'
                },
                {
                    label: 'Alta',
                    value: 'ALTA'
                },
                {
                    label: 'Imediata',
                    value: 'IMEDIATA'
                }
            ],
            grupos: [
                { nome: 'Físico', valor: 1 },
                { nome: 'Químico', valor: 2 },
                { nome: 'Biológico', valor: 3 },
                { nome: 'Ergonômico', valor: 4 },
                { nome: 'Acidente Mecânico', valor: 5 },
                { nome: 'Outro', valor: 6 }
            ],
            prioridades: [
                { nome: 'Baixa', value: 'BAIXA' },
                { nome: 'Moderada', value: 'MODERADA' },
                { nome: 'Alta', value: 'ALTA' },
                { nome: 'Imediata', value: 'IMEDIATA' }
            ],
            actionItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    command: () => this.handleSetEditMode()
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.deletePlan()
                }
            ]
        };
    },
    async mounted() {
        this.addDays();

        this.edit = !!this.$route.query.edit;
        this.$service = new PlanoAcaoCausaService();
        this.$planoAcao = new PlanoAcaoService();

        const { data } = await this.$planoAcao.get('', '', this.$route.params.id);

        if (data) {
            this.planoDeAcao = data;
            this.matriz = data.matrizConfig;
        }

        if (this.$route.params.id) {
            await this.getRisks();
            await this.getMatrizes();
            await this.getDescRisco();
            await this.getAllResultados();
            await this.getCausas();
        }

        if (this.causas?.length) {
            this.riskFactor = true;
        }

        if (!this.planoDeAcao.prazoConclusao && this.planoDeAcao.prazoConclusao !== 0) {
            this.planoDeAcao.prazoConclusao = 0;
        }

        this.planoDeAcao.dataInicial = moment(this.planoDeAcao.dataInicial).toDate();
        this.planoDeAcao.dataPrevisao = moment(this.planoDeAcao.dataPrevisao ? this.planoDeAcao.dataPrevisao : new Date()).toDate();
    },

    watch: {
        riskFactor() {
            if (!this.riskFactor) {
                this.planoDeAcao.matrizConfigId = null;
                this.causas = [];
                this.causasOriginais = this.causas;
            }
        },
        async matriz() {
            if (this.matriz?.id) {
                this.linhas = await this.getLinhas(this.matriz?.id);
                this.colunas = await this.getColunas(this.matriz?.id);

                this.getAllResultados();
            }
        }
    },

    methods: {
        toggleDays(event) {
            this.$refs.opdc.toggle(event);
        },
        async save() {
            const data = await this.$service.getCausaByPlan(this.$route.params.id);
            const originalIds = data.map((element) => element.id);
            const newIds = this.causas.map((element) => element.id);
            originalIds.forEach(async (id) => {
                if (id.length > 7) {
                    if (!newIds.includes(id)) {
                        await this.$service.delete(id).then(() => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Causa removida.',
                                life: 3000
                            });
                        });
                    }
                }
            });

            try {
                if (!this.planoDeAcao.descricaoCausa) {
                    this.submitted = true;
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Descrição de causa obrigatória',
                        life: 3000
                    });
                }
                if (!this.planoDeAcao.prioridadeCausa) {
                    this.submitted = true;
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Prioridade obrigatória',
                        life: 3000
                    });
                }
                const { data } = await this.$planoAcao.get('', '', this.$route.params.id);

                if (new Date(data.dataInicial).getTime() > new Date(this.planoDeAcao.dataPrevisao).getTime()) {
                    this.submitted = true;
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Previsão de conclusão não pode ser anterior a data inicial.',
                        life: 3000
                    });
                }

                if (
                    data.matrizConfigId !== this.planoDeAcao.matrizConfigId ||
                    data.descricaoCausa !== this.planoDeAcao.descricaoCausa ||
                    data.prioridadeCausa !== this.planoDeAcao.prioridadeCausa ||
                    data.prazoConclusao !== this.planoDeAcao.prazoConclusao ||
                    data.dataPrevisao !== this.planoDeAcao.dataPrevisao
                ) {
                    if (!this.planoDeAcao.matrizConfigId) {
                        this.planoDeAcao.matrizConfig = null;
                    }
                    await this.$planoAcao.patch(this.planoDeAcao.id, this.planoDeAcao);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso ao atualizar plano de ação!',
                        life: 3000
                    });
                }
                this.$emit('updatePlan', this.planoDeAcao.id);
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao atualizar plano de ação!',
                    life: 3000
                });
            }

            this.causas.forEach(async (selecionado) => {
                if (selecionado.id.length > 7) {
                    const causaSelecionada = data.find((element) => element.id === selecionado.id);

                    if (
                        selecionado.grupoRisco === causaSelecionada.grupoRisco &&
                        selecionado.eixoXMatriz === causaSelecionada.eixoXMatriz &&
                        selecionado.eixoYMatriz === causaSelecionada.eixoYMatriz &&
                        selecionado.resultadoMatriz === causaSelecionada.resultadoMatriz &&
                        selecionado.riscoId === causaSelecionada.riscoId &&
                        selecionado.riscoDescRisco.id === causaSelecionada.riscoDescRiscoId &&
                        selecionado.matrizConfigLinhaId === causaSelecionada.matrizConfigLinhaId
                    ) {
                        return;
                    }

                    const dataToSave = {
                        planoAcaoId: this.$route.params.id,
                        grupoRisco: selecionado.grupoRisco,
                        eixoXMatriz: String(selecionado.eixoXMatriz.id),
                        eixoYMatriz: String(selecionado.eixoYMatriz.id),
                        resultadoMatriz: selecionado.resultadoMatriz,
                        riscoId: selecionado.risco.Id,
                        riscoDescRiscoId: selecionado.riscoDescRisco.id,
                        matrizConfigLinhaId: selecionado.matrizConfigLinhaId
                    };

                    await this.$service.patch(selecionado.id, dataToSave).then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Causa editada.',
                            life: 3000
                        });
                    });
                } else {
                    const hasMatchingItem = data.some((element) => {
                        return selecionado.risco.id === element.riscoId && selecionado.riscoDescRisco.id === element.riscoDescRiscoId;
                    });

                    if (hasMatchingItem) {
                        return this.$toast.add({
                            severity: 'error',
                            summary: 'Combinação de risco e descrição de risco já cadastradas.',
                            life: 3000
                        });
                    }
                    const resultado = this.possiveisResultados?.allLinhas?.find(
                        (element) =>
                            element.posicaoEixoX == selecionado.eixoXMatriz.posicaoEixoX &&
                            element.posicaoEixoY == selecionado.eixoYMatriz.posicaoEixoY
                    );

                    const dataToSave = {
                        planoAcaoId: this.$route.params.id,
                        grupoRisco: selecionado.grupoRisco,
                        eixoXMatriz: String(selecionado.eixoXMatriz.id),
                        eixoYMatriz: String(selecionado.eixoYMatriz.id),
                        resultadoMatriz: selecionado.resultadoMatriz,
                        riscoId: selecionado.risco.id,
                        riscoDescRiscoId: selecionado.riscoDescRisco.id,
                        matrizConfigLinhaId: resultado.id
                    };

                    await this.$service.post(dataToSave).then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Causa cadastrada.',
                            life: 3000
                        });
                    });
                }
            });
            this.loading = false;
        },
        toggleMenu(event, data) {
            this.selectedCausa = data;
            this.$refs.menu.toggle(event);
        },
        getColunaAtual(id) {
            const coluna = this.colunas.find((element) => element.id == id);

            return coluna ? coluna.titulo : '';
        },
        getLinhaAtual(id) {
            const linha = this.linhas.find((element) => element.id == id);

            return linha ? linha.titulo : '';
        },
        deletePlan() {
            this.causas.forEach((element, index) => {
                if (element.id === this.selectedCausa.id) {
                    this.causas.splice(index, 1);
                }
            });
        },
        async selecionarMatriz() {
            this.getAllResultados();
            this.adicionarPerigo();

            this.planoDeAcao.matrizConfigId = this.matriz?.id;
            this.showModal = false;
        },
        handleSetEditMode() {
            const selectedIndex = this.causas.findIndex((element) => element.id === this.selectedCausa.id);

            this.causas[selectedIndex].edit = true;
        },
        adicionarPerigo() {
            const novoPerigo = {
                edit: true,
                id: `${Math.floor(Math.random() * (100000 - 0 + 1)) + 0}`
            };

            this.selectedCausa = novoPerigo;
            this.causas.push(novoPerigo);
        },
        async getRisks() {
            this.riscos = await this.$service.getRisks();
        },
        async getRiskById(id) {
            return await this.$service.getRiskById(id);
        },
        async getMatrizes() {
            this.matrizes = await this.$service.getMatrizes();
        },
        removerMatriz() {
            if (!this.causas.length) {
                this.planoDeAcao.matrizConfigId = null;
                this.matriz = {};
                this.riskFactor = false;
            }
        },
        async getCausas() {

            const data = await this.$service.getCausaByPlan(this.$route.params.id);

            this.causas = data.map((element) => {
                const resultado = this.getResultado(element.eixoXMatriz, element.eixoYMatriz);

                const tX = this.possiveisResultados.allLinhas?.filter((el) => el.eixo === 'x').find((el) => el.id == element.eixoXMatriz);
                const tY = this.possiveisResultados.allLinhas?.filter((el) => el.eixo === 'y').find((el) => el.id == element.eixoYMatriz);

                return {
                    ...element,
                    resultadoMatriz: resultado.titulo,
                    edit: false,
                    eixoXMatriz: tX,
                    eixoYMatriz: tY
                };
            });

            this.causasOriginais = data.map((element) => {
                const resultado = this.getResultado(element.eixoXMatriz, element.eixoYMatriz);

                const tX = this.possiveisResultados.allLinhas?.filter((el) => el.eixo === 'x').find((el) => el.id == element.eixoXMatriz);
                const tY = this.possiveisResultados.allLinhas?.filter((el) => el.eixo === 'y').find((el) => el.id == element.eixoYMatriz);

                return {
                    ...element,
                    resultadoMatriz: resultado.titulo,
                    edit: false,
                    eixoXMatriz: tX,
                    eixoYMatriz: tY
                };
            });
        },
        async getDescRisco() {
            this.descriptions = await this.$service.getDescRisco();
        },
        async getMatrizesById(id) {
            return await this.$service.getMatrizesById(id);
        },
        async getLinhas(id) {
            return await this.$service.getLinhas(id);
        },
        async getColunas(id) {
            return await this.$service.getColunas(id);
        },
        async getAllResultados() {
                const matrizById = await this.$service.getMatrizesById(this.matriz?.id);

                this.linhas = matrizById.allLinhas.filter((element) => element.eixo === 'x');
                this.colunas = matrizById.allLinhas.filter((element) => element.eixo === 'y');

                this.possiveisResultados = matrizById;

                this.grupoCores = await this.$service.getGrupoCores();
        },
        getResultado(eixoX, eixoY) {
                const pX = this.linhas.find((element) => element.id == eixoX).posicaoEixoX;
                const pY = this.colunas.find((element) => element.id == eixoY).posicaoEixoY;

                const resultado = this.possiveisResultados?.allLinhas
                    ?.filter((element) => element.eixo === 'r')
                    .find((element) => {
                        return element.posicaoEixoX == pX && element.posicaoEixoY == pY;
                    });
                return resultado;
        },
        async updateRiskDependencies(row) {
            this.validateDupdateGroup(row);
            const data = await this.getRiskById(this.causas[row.index].risco.id);
            this.causas[row.index].grupoRisco = data.grupo_risco;
            this.causas[row.index].riscoDescRiscoRiscoId = data.descricaoRisco;
        },
        getDaysDifference(date1, date2) {
            const utcDate1 = moment(date1);
            const utcDate2 = moment(date2);

            this.planoDeAcao.prazoConclusao = utcDate2.diff(utcDate1, 'days');
        },
        addDays() {
            const now = moment(this.planoDeAcao.dataInicial);
            this.planoDeAcao.dataPrevisao = now.add(this.planoDeAcao.prazoConclusao, 'days').toDate();
        },
        validateDupdateGroup(row) {
            const grupoRiscoExistente = this.causas.some((linha) => {
                return linha.risco.id === row.data.risco.id &&
                    linha.riscoDescRisco.id === row.data.riscoDescRisco.id &&
                    linha !== row.data;
            });
            if (grupoRiscoExistente) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Já existe o vincúlo de Perigo/Fator de Risco e Descrição de Risco',
                    life: 3000
                });
                row.data.riscoDescRisco = '';
                row.data.risco = '';
                return;
            }
        },
    }
};
</script>

<style scoped>
textarea {
    resize: none;
}

.b-rounded {
    border-radius: 4px;
}

.risk-level {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    border-radius: 2px;
    height: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table {
    font-weight: 500;
}

.table-container {
    min-height: 100px;
}

.error-message {
    color: red;
    font-size: x-small;
    margin-top: 2px;
}
</style>
