<template>
    <div class="flex flex-column" :class="`${loading && 'pointer-events-none'}`" style="height: 100%">
        <details>
            <summary class="section-header">
                <span class="pi pi-angle-up summary-icon" />Custo de implementação<span class="span-value"
                    >R$
                    {{
                        new Intl.NumberFormat('de-DE').format(this.totalImplementacao).split(',')[0] +
                        ',' +
                        (new Intl.NumberFormat('de-DE').format(this.totalImplementacao).split(',')[1] ?? '00')
                    }}</span
                >
            </summary>
            <DataTable
                dataKey="id"
                :value="custoImplementacao"
                :row-hover="true"
                responsiveLayout="scroll"
                breakpoint="640px"
                :rows="10"
                style="cursor: pointer"
                class="mb-3"
            >
                <template #empty>Nenhum registro encontrado.</template>
                <template #loading>Carregando registros. Aguarde...</template>
                <Column field="descricaoCusto" header="Descrição" :sortable="true" sortField="status">
                    <template #body="props">
                        <InputText maxlength="50" :id="props.field" v-model="props.data[props.field]" style="width: 100%" v-if="props.data.edit" />
                        <label class="status-display" v-else>
                            {{ props.data?.descricaoCusto }}
                        </label>
                    </template>
                </Column>
                <Column field="quantidade" header="Quantidade" :sortable="true" sortField="prioridadeAcao">
                    <template #body="props">
                        <InputText
                            type="number"
                            :id="props.field"
                            v-model="props.data[props.field]"
                            style="width: 100%"
                            :min="0"
                            @change="
                                (e) => {
                                    props.data.valorTotal = (props.data.quantidade || 0) * (props.data.valorUnitario || 0);
                                }
                            "
                            v-if="props.data.edit"
                        />
                        <label v-else>
                            {{ props.data?.quantidade }}
                        </label>
                    </template>
                </Column>
                <Column field="valorUnitario" header="Valor Unitário" :sortable="true" sortField="dataInicial">
                    <template #body="props">
                        <InputNumber
                            inputId="minmaxfraction"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            :id="props.field"
                            v-model="props.data[props.field]"
                            style="width: 100%"
                            :min="0"
                            @input="
                                (e) => {
                                    props.data.valorTotal = (props.data.quantidade || 0) * (e.value || 0);
                                }
                            "
                            v-if="props.data.edit"
                        />
                        <label v-else>
                            {{
                                props.data?.valorUnitario
                                    ? `R$ ${
                                          new Intl.NumberFormat('de-DE').format(props.data?.valorUnitario).split(',')[0] +
                                          ',' +
                                          (new Intl.NumberFormat('de-DE').format(props.data?.valorUnitario).split(',')[1] ?? '00')
                                      }`
                                    : ''
                            }}
                        </label>
                    </template>
                </Column>
                <Column field="valorTotal" header="Valor Total" :sortable="true" sortField="customerId">
                    <template #body="props">
                        <InputNumber
                            inputId="minmaxfraction"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            :id="props.field"
                            :min="0"
                            v-model="props.data.valorTotal"
                            style="width: 100%"
                            v-if="props.data.edit"
                            disabled
                        />
                        <label v-else>
                            {{
                                props.data?.valorTotal
                                    ? `R$ ${
                                          new Intl.NumberFormat('de-DE').format(props.data?.valorTotal).split(',')[0] +
                                          ',' +
                                          (new Intl.NumberFormat('de-DE').format(props.data?.valorTotal).split(',')[1] ?? '00')
                                      }`
                                    : ''
                            }}
                        </label>
                    </template>
                </Column>
                <Column field="" bodyClass="text-right" header="" style="width: 4%">
                    <template #body="props">
                        <Button
                            v-if="!props.data.edit"
                            icon="pi pi-ellipsis-v"
                            class="p-button-text p-button-secondary"
                            @click="toggleMenuImplementacao($event, props)"
                        />
                        <div class="flex" v-else>
                            <Button
                                icon="pi pi-times"
                                class="p-button-text p-button-secondary"
                                @click="
                                    () => {
                                        const custoOriginal = this.custoImplementacaoOriginal.find((_, index) => index === props.index);
                                        if (!custoOriginal) {
                                            return this.handleRemoveCusto(false);
                                        }

                                        props.data.descricaoCusto = custoOriginal.descricaoCusto;
                                        props.data.quantidade = custoOriginal.quantidade;
                                        props.data.valorUnitario = custoOriginal.valorUnitario;
                                        props.data.valorTotal = custoOriginal.valorTotal;
                                        props.data.edit = false;
                                    }
                                "
                            />
                        </div>
                    </template>
                </Column>
                <Menu ref="menuImplementacao" :model="actionItemsImplementacao" :popup="true" />
            </DataTable>
            <Button text label="Adicionar custo" icon="pi pi-plus" class="p-button-text" @click="(e) => addCusto(false)" />
        </details>
        <details>
            <summary class="section-header">
                <span class="pi pi-angle-up summary-icon" />Possíveis passivos trabalhistas<span class="span-value"
                    >R$
                    {{
                        new Intl.NumberFormat('de-DE').format(this.totalRetido).split(',')[0] +
                        ',' +
                        (new Intl.NumberFormat('de-DE').format(this.totalRetido).split(',')[1] ?? '00')
                    }}</span
                >
            </summary>
            <DataTable
                dataKey="id"
                :value="custoRetido"
                :row-hover="true"
                responsiveLayout="scroll"
                breakpoint="640px"
                :rows="10"
                style="cursor: pointer"
                class="mb-3"
            >
                <template #empty>Nenhum registro encontrado.</template>
                <template #loading>Carregando registros. Aguarde...</template>
                <Column field="baseLegal" header="Base Legal" :sortable="true" sortField="status">
                    <template #body="props">
                        <Dropdown
                            :id="props.field"
                            v-model="props.data[props.field]"
                            :options="basesLegais"
                            optionLabel="nome"
                            style="width: 100%"
                            class="input-select"
                            v-if="props.data.edit"
                        />
                        <p class="status-display" v-else>
                            {{ props.data?.baseLegal?.nome }}
                        </p>
                    </template>
                </Column>
                <Column field="descricaoCusto" header="Descrição" :sortable="true" sortField="status">
                    <template #body="props">
                        <InputText maxlength="50" :id="props.field" v-model="props.data[props.field]" style="width: 100%" v-if="props.data.edit" />
                        <label class="status-display" v-else>
                            {{ props.data?.descricaoCusto }}
                        </label>
                    </template>
                </Column>
                <Column field="quantidade" header="Quantidade" :sortable="true" sortField="prioridadeAcao">
                    <template #body="props">
                        <InputText
                            type="number"
                            :id="props.field"
                            v-model="props.data[props.field]"
                            style="width: 100%"
                            :min="0"
                            @change="
                                (e) => {
                                    props.data.valorTotal = (props.data.quantidade || 0) * (props.data.valorUnitario || 0);
                                }
                            "
                            v-if="props.data.edit"
                        />
                        <label v-else>
                            {{ props.data?.quantidade }}
                        </label>
                    </template>
                </Column>
                <Column field="valorUnitario" header="Valor Unitário" :sortable="true" sortField="dataInicial">
                    <template #body="props">
                        <InputNumber
                            inputId="minmaxfraction"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            :id="props.field"
                            :min="0"
                            v-model="props.data[props.field]"
                            style="width: 100%"
                            @input="
                                (e) => {
                                    props.data.valorTotal = (props.data.quantidade || 0) * (e.value || 0);
                                }
                            "
                            v-if="props.data.edit"
                        />
                        <label v-else>
                            {{
                                props.data?.valorUnitario
                                    ? `R$ ${
                                          new Intl.NumberFormat('de-DE').format(props.data?.valorUnitario).split(',')[0] +
                                          ',' +
                                          (new Intl.NumberFormat('de-DE').format(props.data?.valorUnitario).split(',')[1] ?? '00')
                                      }`
                                    : ''
                            }}
                        </label>
                    </template>
                </Column>
                <Column field="valorTotal" header="Valor Total" :sortable="true" sortField="customerId">
                    <template #body="props">
                        <InputNumber
                            inputId="minmaxfraction"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            :id="props.field"
                            :min="0"
                            v-model="props.data.valorTotal"
                            style="width: 100%"
                            v-if="props.data.edit"
                            disabled
                        />
                        <label v-else>
                            {{
                                props.data?.valorTotal
                                    ? `R$ ${
                                          new Intl.NumberFormat('de-DE').format(props.data?.valorTotal).split(',')[0] +
                                          ',' +
                                          (new Intl.NumberFormat('de-DE').format(props.data?.valorTotal).split(',')[1] ?? '00')
                                      }`
                                    : ''
                            }}
                        </label>
                    </template>
                </Column>
                <Column field="" bodyClass="text-right" header="" style="width: 4%">
                    <template #body="props">
                        <Button
                            v-if="!props.data.edit"
                            icon="pi pi-ellipsis-v"
                            class="p-button-text p-button-secondary"
                            @click="toggleMenuRetido($event, props)"
                        />
                        <div class="flex" v-else>
                            <Button
                                icon="pi pi-times"
                                class="p-button-text p-button-secondary"
                                @click="
                                    () => {
                                        const custoOriginal = this.custoRetidoOriginal.find((_, index) => index === props.index);
                                        if (!custoOriginal) {
                                            return this.handleRemoveCusto(true);
                                        }

                                        props.data.descricaoCusto = custoOriginal.descricaoCusto;
                                        props.data.quantidade = custoOriginal.quantidade;
                                        props.data.valorUnitario = custoOriginal.valorUnitario;
                                        props.data.valorTotal = custoOriginal.valorTotal;
                                        props.data.edit = false;
                                    }
                                "
                            />
                        </div>
                    </template>
                </Column>
                <Menu ref="menuRetido" :model="actionItemsRetido" :popup="true" />
            </DataTable>
            <Button text label="Adicionar custo" icon="pi pi-plus" class="p-button-text" @click="(e) => addCusto(true)" />
        </details>
        <div class="flex justify-content-end" style="margin-top: auto">
            <Button label="Voltar" @click="$router.push('/plano-acao/listar')" icon="pi pi-home" iconPos="left" type="button" class="mr-2" />
            <Button
                label="Salvar"
                @click="save"
                icon="pi pi-save"
                iconPos="left"
                :disabled="!this.custoRetido.length && !this.custoImplementacao.length"
            />
        </div>
    </div>
</template>

<script>
import PlanoAcaoCustosService from '../../../services/planoAcaoCustosService';
import BaseService from '../../../services/BaseService';

export default {
    data() {
        return {
            custoImplementacao: [],
            custoImplementacaoOriginal: [],
            custoRetido: [],
            custoRetidoOriginal: [],
            submitted: false,
            loading: false,
            totalRetido: 0,
            totalImplementacao: 0,
            selectedCustoRetido: {},
            selectedCustoImplementacao: {},
            basesLegais: [],
            actionItemsRetido: [
                {
                    label: () => {
                        return this.selectedCustoRetido.data.edit ? 'Salvar' : 'Alterar';
                    },
                    icon: 'pi pi-pencil',
                    command: () => this.handleSetEditMode(true)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.handleRemoveCusto(true)
                }
            ],
            actionItemsImplementacao: [
                {
                    label: () => {
                        return this.selectedCustoImplementacao.data.edit ? 'Salvar' : 'Alterar';
                    },
                    icon: 'pi pi-pencil',
                    command: () => this.handleSetEditMode(false)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.handleRemoveCusto(false)
                }
            ]
        };
    },
    async mounted() {
        this.$service = new PlanoAcaoCustosService();
        if (this.$route.params.id) {
            await this.getCustos();
            await this.getBaseLegal();
        }
        this.calcularTotais();
    },
    methods: {
        async save() {
            this.submitted = true;
            this.loading = true;

            const { data } = await this.$service.get(`combo-plano-acao/${this.$route.params.id}`);

            const originalIdsImplementacao = data.filter((element) => Boolean(!element.baseLegalId)).map((element) => element.id);
            const newIdsImplementacao = this.custoImplementacao.map((element) => element.id);

            originalIdsImplementacao.forEach(async (id) => {
                if (id.length > 7) {
                    if (!newIdsImplementacao.includes(id)) {
                        await this.$service.delete(id).then(() => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Custo removido.',
                                life: 3000
                            });
                        });
                    }
                }
            });

            const originalIdsRetido = data.filter((element) => Boolean(element.baseLegalId)).map((element) => element.id);
            const newIdsRetido = this.custoRetido.map((element) => element.id);

            originalIdsRetido.forEach(async (id) => {
                if (id.length > 7) {
                    if (!newIdsRetido.includes(id)) {
                        await this.$service.delete(id).then(() => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Custo removido.',
                                life: 3000
                            });
                        });
                    }
                }
            });

            this.custoImplementacao.forEach(async (custo, index) => {
                try {
                    if (custo.id.length > 7) {
                        const custoSelecionado = data.find((element) => element.id === custo.id);

                        if (
                            custo.descricaoCusto === custoSelecionado.descricaoCusto &&
                            Number(custo.quantidade) === custoSelecionado.quantidade &&
                            custo.valorUnitario === custoSelecionado.valorUnitario &&
                            custo.valorTotal === custoSelecionado.valorTotal
                        ) {
                            return;
                        }

                        if (
                            data
                                .filter((element) => !element.baseLegalId)
                                .map((element) => element.descricaoCusto)
                                .includes(custo.descricaoCusto)
                        ) {
                            return this.$toast.add({
                                severity: 'error',
                                summary: 'Descrição de custo repetida.',
                                life: 3000
                            });
                        }

                        const dataToSave = {
                            planoAcaoId: this.$route.params.id,
                            descricaoCusto: custo.descricaoCusto,
                            quantidade: Number(custo.quantidade),
                            valorUnitario: Number.parseFloat(custo.valorUnitario.toFixed(2)),
                            valorTotal: Number.parseFloat(custo.valorTotal.toFixed(2)),
                            tipo: 'IMPLEMENTACAO'
                        };

                        await this.$service.patch(custo.id, dataToSave).then((res) => {
                            this.custoImplementacao[index] = res.data;
                            this.custoImplementacaoOriginal[index] = res.data;
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Custo editado.',
                                life: 3000
                            });
                        });
                    } else {
                        if (
                            data
                                .filter((element) => !element.baseLegalId)
                                .map((element) => element.descricaoCusto)
                                .includes(custo.descricaoCusto)
                        ) {
                            return this.$toast.add({
                                severity: 'error',
                                summary: 'Descrição de custo repetida.',
                                life: 3000
                            });
                        }

                        const dataToSave = {
                            planoAcaoId: this.$route.params.id,
                            descricaoCusto: custo.descricaoCusto,
                            quantidade: Number(custo.quantidade),
                            valorUnitario: Number.parseFloat(custo.valorUnitario.toFixed(2)),
                            valorTotal: Number.parseFloat(custo.valorTotal.toFixed(2)),
                            tipo: 'IMPLEMENTACAO'
                        };

                        await this.$service.post(dataToSave).then((res) => {
                            this.custoImplementacao[index] = res.data;
                            this.custoImplementacaoOriginal[index] = res.data;
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Custo cadastrado.',
                                life: 3000
                            });
                        });
                    }
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Erro no cadastro de custos', life: 3000 });
                }
            });

            this.custoRetido.forEach(async (custo, index) => {
                try {
                    if (custo.id.length > 7) {
                        const custoSelecionado = data.find((element) => element.id === custo.id);

                        if (
                            custo.descricaoCusto === custoSelecionado.descricaoCusto &&
                            custo.quantidade === custoSelecionado.quantidade &&
                            custo.valorUnitario === custoSelecionado.valorUnitario &&
                            custo.valorTotal === custoSelecionado.valorTotal &&
                            custo.baseLegal.id === custoSelecionado.baseLegalId
                        ) {
                            return;
                        }

                        if (
                            data
                                .filter((element) => element.baseLegalId)
                                .map((element) => element.descricaoCusto)
                                .includes(custo.descricaoCusto)
                        ) {
                            return this.$toast.add({
                                severity: 'error',
                                summary: 'Descrição de custo repetida.',
                                life: 3000
                            });
                        }

                        const dataToSave = {
                            planoAcaoId: this.$route.params.id,
                            descricaoCusto: custo.descricaoCusto,
                            quantidade: Number(custo.quantidade),
                            valorUnitario: custo.valorUnitario,
                            valorTotal: custo.valorTotal,
                            tipo: 'RETIDO',
                            baseLegalId: custo.baseLegal.id
                        };

                        await this.$service.patch(custo.id, dataToSave).then((res) => {
                            this.custoRetido[index] = res.data;
                            this.custoRetidoOriginal[index] = res.data;
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Custo editado.',
                                life: 3000
                            });
                        });
                    } else {
                        if (
                            data
                                .filter((element) => element.baseLegalId)
                                .map((element) => element.descricaoCusto)
                                .includes(custo.descricaoCusto)
                        ) {
                            return this.$toast.add({
                                severity: 'error',
                                summary: 'Descrição de custo repetida.',
                                life: 3000
                            });
                        }

                        const dataToSave = {
                            planoAcaoId: this.$route.params.id,
                            descricaoCusto: custo.descricaoCusto,
                            quantidade: Number(custo.quantidade),
                            valorUnitario: custo.valorUnitario,
                            valorTotal: custo.valorTotal,
                            tipo: 'RETIDO',
                            baseLegalId: custo.baseLegal.id
                        };

                        await this.$service.post(dataToSave).then((res) => {
                            this.custoRetido[index] = res.data;
                            this.custoRetidoOriginal[index] = res.data;
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Custo cadastrado.',
                                life: 3000
                            });
                        });
                    }
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Erro no cadastro de custos', life: 3000 });
                }
            });
            this.loading = false;
        },
        async getCustos() {
            const { data } = await this.$service.get(`combo-plano-acao/${this.$route.params.id}`);

            this.custoImplementacao = data.filter((element) => Boolean(!element.baseLegalId));
            this.custoImplementacaoOriginal = [...data.filter((element) => Boolean(!element.baseLegalId))];
            this.custoRetido = data.filter((element) => Boolean(element.baseLegalId));
            this.custoRetidoOriginal = [...data.filter((element) => Boolean(element.baseLegalId))];
        },
        async getBaseLegal() {
            const { data } = await new BaseService('base-legal').findAll({});

            this.basesLegais = data;
        },
        addCusto(retido) {
            const novoCusto = { id: `${Math.floor(Math.random() * (100000 - 0 + 1)) + 0}`, edit: true };
            if (retido) {
                novoCusto.index = this.custoRetido.length;
                this.selectedCustoRetido = novoCusto;
                this.custoRetido.push(novoCusto);
            } else {
                novoCusto.index = this.custoImplementacao.length;
                this.selectedCustoImplementacao = novoCusto;
                this.custoImplementacao.push(novoCusto);
            }
        },
        handleSetEditMode(retido) {
            if (retido) {
                if (this.custoRetido[this.selectedCustoRetido.index].edit) {
                    if (
                        !this.custoRetido[this.selectedCustoRetido.index].baseLegal ||
                        !this.custoRetido[this.selectedCustoRetido.index].descricaoCusto ||
                        !this.custoRetido[this.selectedCustoRetido.index].quantidade ||
                        !this.custoRetido[this.selectedCustoRetido.index].valorUnitario ||
                        !this.custoRetido[this.selectedCustoRetido.index].valorTotal
                    ) {
                        return this.$toast.add({
                            severity: 'error',
                            summary: 'Alguns campos não foram preenchidos!',
                            life: 3000
                        });
                    }
                }

                this.custoRetido[this.selectedCustoRetido.index] = {
                    ...this.custoRetido[this.selectedCustoRetido.index],
                    edit: !this.custoRetido[this.selectedCustoRetido.index].edit
                };

                if (!this.custoRetido[this.selectedCustoRetido.index].edit) {
                    this.calcularTotais();
                }
            } else {
                if (this.custoImplementacao[this.selectedCustoImplementacao.index].edit) {
                    if (
                        !this.custoImplementacao[this.selectedCustoImplementacao.index].descricaoCusto ||
                        !this.custoImplementacao[this.selectedCustoImplementacao.index].quantidade ||
                        !this.custoImplementacao[this.selectedCustoImplementacao.index].valorUnitario ||
                        !this.custoImplementacao[this.selectedCustoImplementacao.index].valorTotal
                    ) {
                        return this.$toast.add({
                            severity: 'error',
                            summary: 'Alguns campos não foram preenchidos!',
                            life: 3000
                        });
                    }
                }

                this.custoImplementacao[this.selectedCustoImplementacao.index] = {
                    ...this.custoImplementacao[this.selectedCustoImplementacao.index],
                    edit: !this.custoImplementacao[this.selectedCustoImplementacao.index].edit
                };

                if (!this.custoImplementacao[this.selectedCustoImplementacao.index].edit) {
                    this.calcularTotais();
                }
            }
        },
        handleRemoveCusto(retido) {
            if (retido) {
                this.custoRetido.splice(this.selectedCustoRetido.index, 1);
            } else {
                this.custoImplementacao.splice(this.selectedCustoImplementacao.index, 1);
            }
            this.calcularTotais();
        },
        toggleMenuRetido(event, data) {
            this.selectedCustoRetido = data;
            this.$refs.menuRetido.toggle(event);
        },
        toggleMenuImplementacao(event, data) {
            this.selectedCustoImplementacao = data;
            this.$refs.menuImplementacao.toggle(event);
        },
        calcularTotais() {
            this.totalImplementacao = this.custoImplementacao
                .map((element) => element.valorTotal)
                .filter((element) => !isNaN(Number(element)))
                .reduce((a, b) => a + Number(b), 0);
            this.totalRetido = this.custoRetido
                .map((element) => element.valorTotal)
                .filter((element) => !isNaN(Number(element)))
                .reduce((a, b) => a + Number(b), 0);
        },
        formatNumber() {
            this.props.data[this.props.field] = parseFloat(this.props.data[this.props.field])?.toFixed(2);
        }
    },
    watch: {}
};
</script>

<style scoped>
.section-header {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    gap: 2rem;
    align-items: center;
    height: 5rem;
    font-weight: 500;
}
.span-value {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
details summary {
    cursor: pointer;
    user-select: none;
}
.summary-icon {
    font-size: 1.5rem;
    transition: 0.5s ease;
}
details[open] summary .summary-icon {
    transform: rotate(180deg);
    transition: 0.5s ease;
}
</style>
