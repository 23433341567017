<template>
    <div>
        <Toast />

        <router-view :key="$route.path">
            <div class="card box flex flex-column" :key="planoAcao">
                <Dialog v-model:visible="confirmStartPlan">
                    <div class="remove-dialog p-5 pt-0">
                        <h3>Você tem certeza que deseja iniciar este Plano de ação?</h3>
                    </div>
                    <template #footer>
                        <Button label="Voltar" icon="pi pi-arrow-left" @click="confirmStartPlan = false" class="p-button-outlined mr-4 b-rounded" />
                        <Button label="Confirmar" @click="this.startPlan()" class="mr-4 b-rounded" />
                    </template>
                </Dialog>
                <header class="flex justify-content-between gap-8 align-items-center px-6 pt-6">
                    <div class="mw-25">
                        <div class="breadcrumb-container flex mb-4 gap-4">
                            <span class="pi pi-home"></span>
                            <span class="pi pi-angle-right"></span>
                            <p class="text-xl">SESMT</p>
                            <span class="pi pi-angle-right"></span>
                            <p class="text-xl cursor-pointer">Plano de Ação</p>
                        </div>
                        <div>
                            <h4>
                                {{ this.planoAcao?.customer?.name ? this.planoAcao.customer.name : 'Cliente' }} |
                                {{ this.planoAcao?.unidade?.name ? this.planoAcao.unidade.name : 'Unidade' }}
                            </h4>
                        </div>
                    </div>
                    <Steps
                        :readonly="!this.planoAcao.status || this.planoAcao.status === 'PENDENTE'"
                        :model="items"
                        aria-label="Form Steps"
                        class="flex-1"
                    />
                    <Button @click="this.confirmStartPlan = true" :disabled="this.planoAcao.status !== 'PENDENTE'" class="mb-4"
                        ><span class="pi pi-caret-right"></span> &nbsp;&nbsp;Iniciar plano de ação</Button
                    >
                </header>

                <div class="flex gap-8 mt-4 px-6">
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar mt-2" style="font-size: 20px"></i>
                        <p class="mt-3">
                            Data inicial:
                            {{
                                this.planoAcao.dataInicial
                                    ? `${this.planoAcao.dataInicial?.split('-')[2]}/${this.planoAcao.dataInicial?.split('-')[1]}/${
                                          this.planoAcao.dataInicial?.split('-')[0]
                                      }`
                                    : ''
                            }}
                        </p>
                    </div>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar mt-2" style="font-size: 20px"></i>
                        <p class="mt-3">
                            Data prevista de conclusão:
                            {{
                                this.planoAcao.dataPrevisao
                                    ? `${this.planoAcao.dataPrevisao?.split('-')[2]}/${this.planoAcao.dataPrevisao?.split('-')[1]}/${
                                          this.planoAcao.dataPrevisao?.split('-')[0]
                                      }`
                                    : ''
                            }}
                        </p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Objetivo: {{ this.planoAcao.objetivoAcao }}</p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Prioridade: {{ this.planoAcao.prioridadeCausa }}</p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Nome da ação: {{ this.planoAcao.nomeAcao }}</p>
                    </div>
                    <div class="flex align-items-center gap-2" style="margin-left: auto">
                        <p class="mt-3">
                            Status: <span :class="`${this.planoAcao.status}`">{{ this.planoAcao.status?.replace('_', ' ') }}</span>
                        </p>
                    </div>
                </div>
                <div class="px-6 my-4">
                    <Divider />
                </div>

                <TabView class="tab-view" v-model:activeIndex="activeTab">
                    <TabPanel header="Definição">
                        <Definicao @updatePlan="updatePlan" :status="planoAcao.status" />
                    </TabPanel>
                    <TabPanel header="Causa" :disabled="!this.$route.params.id" contentStyle="height: 100%">
                        <Causa :status="planoAcao.status" @updatePlan="updatePlan" />
                    </TabPanel>
                    <TabPanel header="Estrutura organizacional" :disabled="!this.$route.params.id" contentStyle="height: 100%">
                        <EstruturaOrganizacional :status="planoAcao.status" />
                    </TabPanel>
                    <TabPanel header="Pessoas envolvidas" :disabled="!this.$route.params.id" contentStyle="height: 100%">
                        <PessoasEnvolvidas :status="planoAcao.status" />
                    </TabPanel>
                    <TabPanel header="Orçamento" :disabled="!this.$route.params.id" contentStyle="height: 100%">
                        <Orcamento :status="planoAcao.status" />
                    </TabPanel>
                </TabView>
            </div>
        </router-view>
    </div>
</template>

<script>
import Definicao from './components/definicao.vue';
import Causa from './components/causa.vue';
import EstruturaOrganizacional from './components/estrutura-organizacional.vue';
import PessoasEnvolvidas from './components/pessoas-envolvidas.vue';
import PlanoAcaoService from '../../services/planoAcaoService';
import PlanoAcaoEnvolvidosService from '../../services/planoAcaoEnvolvidosService';
import PlanoAcaoHierarquiaService from '../../services/planoAcaoHierarquiaService';
import Orcamento from './components/orcamento.vue';

export default {
    components: {
        Definicao,
        Causa,
        EstruturaOrganizacional,
        PessoasEnvolvidas,
        Orcamento
    },
    data() {
        return {
            customerName: '',
            branchName: '',
            startingDate: new Date(),
            name: '',
            objective: '',
            status: '',
            planoAcao: {},
            activeStepIndex: 0,
            confirmStartPlan: false,
            activeTab: 0,
            items: [
                {
                    label: 'Plano Ação',
                    to: this.itemTo
                },
                {
                    label: 'Acompanhamento',
                    to: `/plano-acao/${this.$route.params.id}/acompanhamento`
                },
                {
                    label: 'Anexos',
                    to: `/plano-acao/${this.$route.params.id}/anexos`
                },
                {
                    label: 'Conclusão',
                    to: `/plano-acao/${this.$route.params.id}/conclusao`
                }
            ]
        };
    },
    async mounted() {
        this.$service = new PlanoAcaoService();

        if (this.$route.params.id) {
            const { data } = await this.$service.get('', '', this.$route.params.id);

            this.planoAcao = data;
        }
    },
    methods: {
        itemTo() {
            if (this.$route.params.id) {
                return `/plano-acao/${this.$route.params.id}`;
            } else {
                return '/plano-acao/criar';
            }
        },
        onStepChange(event) {
            this.activeStepIndex = event.index;
        },
        async updatePlan(id) {
            const { data } = await this.$service.get('', '', id);

            this.planoAcao = data;
        },
        async startPlan() {
            let envolvidos = { data: [] };
            let hierarquias = { data: [] };

            if (this.$route.params.id) {
                envolvidos = await new PlanoAcaoEnvolvidosService().get(`combo-plano-acao/${this.$route.params.id}`);
                hierarquias = await new PlanoAcaoHierarquiaService().get(`combo-plano-acao/${this.$route.params.id}`);
                const { data } = await this.$service.get('', '', this.$route.params.id);
                this.planoAcao = data;
            }

            if (
                (!this.planoAcao.matrizConfigId && !this.planoAcao.descricaoCausa) ||
                (!Number(this.planoAcao.prazoConclusao) && !Number(this.planoAcao.prazoConclusao) === 0) ||
                !this.planoAcao.prioridadeCausa ||
                !this.planoAcao.dataPrevisao
            ) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Complete os dados na aba de Causa',
                    life: 3000
                });

                return (this.activeTab = 1);
            }

            if (!hierarquias.data.length) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Complete os dados na aba de Estrutura organizacional, ao menos uma é necessária',
                    life: 3000
                });

                return (this.activeTab = 2);
            }

            if (!envolvidos.data.length) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Complete os dados na aba de Pessoas Envolvidas, ao menos uma pessoa necessária',
                    life: 3000
                });

                return (this.activeTab = 3);
            }

            if (!envolvidos.data.some((element) => element.responsavel)) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Complete os dados na aba de Pessoas Envolvidas, ao menos um responsável é necessário',
                    life: 3000
                });

                return (this.activeTab = 3);
            }

            await this.$service.patch(this.$route.params.id, { status: 'EM_ANDAMENTO' }).then(() => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Plano inicializado com sucesso',
                    life: 3000
                });
            });

            this.confirmStartPlan = false;

            const { data } = await this.$service.get('', '', this.$route.params.id);
            this.planoAcao = data;
        }
    }
};
</script>

<style scoped>
.breadcrumb-container {
    color: #495057;
}

.pi-home,
.pi-angle-right {
    font-size: 2rem;
}

.PENDENTE {
    background-color: #f7ffc6;
    color: #504d05;
}

.EM_ANDAMENTO {
    background-color: #c3f3f6;
    color: #0d0540;
}

.CONCLUIDO {
    background-color: #c3f6d9;
    color: #0e4005;
}

.EXCLUIDO {
    background-color: #ffd3d3;
    color: #700d06;
}
.box {
    min-height: 90%;
    margin-bottom: 40px;
}
.tab-view {
    box-sizing: border-box;
}

.remove-dialog > h3 {
    font-size: 24px;
}
</style>
