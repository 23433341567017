import { getCompany } from './auth';
import { getClientBase } from './http';

export default class PlanoAcaoEnvolvidosService {
    constructor(path = 'plano-acao-envolvidos') {
        this.path = path;
        this.xApiKey = getCompany();
    }
    async post(body) {
        return getClientBase().post(this.path, body, { headers: { 'Content-Type': 'application/json' } });
    }
    async get(id = '') {
        return getClientBase().get(`${this.path}${id ? `/${id}` : ''}`, { headers: { 'Content-Type': 'application/json' } });
    }
    async patch(id = '', body) {
        if (!id) throw new Error('Id do envolvido inválido');
        return getClientBase().patch(`${this.path}/${id}`, body, { headers: { 'Content-Type': 'application/json' } });
    }
    async delete(id = '') {
        if (!id) throw new Error('Id do envolvido inválido');
        return getClientBase().delete(`${this.path}/${id}`);
    }
}
